import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import IndexCard from "../components/index-card"
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup
} from 'react-bootstrap';
import resume from "./Resume-Logan-Besecker.pdf"


const ProjectsPage = () => (
  <Layout>
    <SEO title="Logan Besecker's Projects" />
    <Container>
     <Row>
      <h1>Logan Besecker's Projects</h1>
      <p>These are projects that I've worked on over the past year as part of my solo journey into the entrepreneur world.  I've created about 5 different websites / Minimum Viable Products. In each of these projects I've learned valuable skills and had a variety of successes. Below are some small descriptions of these projects with more detailed descriptions on separate pages.</p>
       
     </Row>
     <Row>
      <h2>Make Data Searchable</h2>
      <p>Make Data Searchable is a service, currently in closed beta, that converts spreadsheets into content-filled websites that use templated articles with data from the spreadsheets.  This results in the ability to easily create huge websites that will passively rank in search engines for keywords about your industry.</p>
      <p>The technical stack for this project was Python, Flask, PostgreSQL, and of course JS and CSS.  This was the first Flask app I created from scratch under Stingray Technologies and while it wasn't that technically demanding, I learned a lot from this project.</p>
     </Row>
    <Row>
      <h2>GroupChant</h2>
      <p>Group chant was a chatroom-like site that was focused specifically on the 2019 NBA Finals of the Golden State Warriors vs the Toronto Raptors.  It featured three rooms, one for each team and one for the two teams to trash-talk eachother.</p>
      <p>The technical stack for this project was Elixir, Phoenix, PostgreSQL, and of course JS and CSS.  It was fun implementing realtime chat with Phoenix based on its channels and Phoenix turned out to be a fun framework to create a website in.  I look forward to creating a v2 for this project at some point in the future.</p>
     </Row>
    <Row>
      <h2>WoWClassicRaids</h2>
      <p>WoWClassicRaids is a website centered around the gaming community for World of Warcraft Classic.  It's dedicated to helping players connect with guilds in their journey of WoW Classic guild recruitment.  In addition to <a href="https://wow.classicraids.com/guilds">Classic WoW guild recruitment</a>, we help the community through endgame content and other facets of the game.</p>
      <p>Over the last 5 months, I've grown this community to around 1000 registered members and grow at a rate around 5% per week.</p>
      <p>The current technical stack is similar to GroupChant's in that it's made with Elixir, Phoenix, PostgreSQL, JS, and CSS.  However, I'm in the process of migragting over to Node.js, hosted on Firebase for the backend because I've noticed several advantages to doing so.</p>
      <p>Check out <a href="https://wow.classicraids.com">WoWClassicRaids</a> for yourself!</p>
     </Row>
     <Row>
       <h2>API2Site</h2>
       <p>API2Site is a service that makes it easy to convert APIs into full-fledged websites.  It currently supports the creation of web 1.0 websites, but it should be able to create web 2.0 websites in the near future.  With a few simple forms and customizable templates, it's possible to dynamically create massive websites by utilizing the data from your APIs.</p>
       <p>A simple demo for this is <a href="https://picturestakenwith.com/cameras">Pictures Taken With</a>.  It took about 5 minutes to create once the templates were finalized which demonstrates the power that API2Site can possess.  PicturesTakenWith is a website that shows sample photos taken by specific camera makes and models, helping photographers choose the right camera for their needs.</p>
     </Row>
     <Row>
       <h2>Give and Get Leads</h2>
       <p>Give and Get Leads is a service centered around giveaways at regular intervals (daily, weekly, monthly, etc) as a way to generate leads for a business.  Currently we are testing it and have been mildly successful since the start, especially given the constant cost of the giveaway prizes.</p>
     </Row>
     <Row>
       <h2>Contact Me</h2>
       <p>If you wish to join one of the closed betas for one of these products; have questions regarding them; or simply want to learn more about me then please feel free to contact me!  Contact information can be found in my resume on this site.</p>
     </Row>
    </Container>
  </Layout>
)

export default ProjectsPage
